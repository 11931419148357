import React, { useState, useRef, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { MapPin, Coffee, ShoppingBag, Stethoscope } from 'lucide-react';
import Collins from './Images/DollarGeneral.jpg';
import Dollar from './Images/DollarTree.jpg';
import Starbucks from './Images/starbsimage.jpg';
import MWHS from './Images/mwhs.jpg';
import Lima from './Images/lima.jpg';
import BB from './Images/bestbuy.jpg';
import ComingSoon from './Images/ImageCS.jpg';
import GP from './Images/georgepalmer.jpg';
import Killeen from './Images/killeen.jpg';
import Lanham from './Images/lanham.jpg';
import Sheetz from './Images/sheetz.jpg'
import Raisincanes from './Images/raisincanes.jpg'
const properties = [
  {
    name: "Killeen Mall",
    location: "Killeen, Texas",
    description: "Killeen Mall is a thriving 554,000 square foot indoor mall located in Killeen, Texas, a suburb of Austin. Purchased by The Rocky Companies in 2024, the mall features name-brand stores such as Dillards, Burlington, and JCPenney, with junior anchors TJMaxx and Home Goods scheduled to open in 2025. The mall boasts a 90% occupancy rate and well above national average sales volume figures. For Leasing Availability.",
    image: Killeen,
    icon: ShoppingBag,
    mapLink: 'https://www.google.com/maps/place/Killeen+Mall/@31.0929646,-97.7197839,16z/data=!3m1!4b1!4m6!3m5!1s0x86454bbf4319351b:0x921bdab82b931add!8m2!3d31.09296!4d-97.717209!16s%2Fm%2F0pcv7jn?entry=ttu'
  },
  {
    name: "Lima Town Center",
    location: "Lima, Ohio",
    description: "Situated in the vibrant city of Lima, Ohio, Lima Town Center spans an impressive 745,000 square feet, blending indoor and outdoor retail experiences. Nestled just west of route I-75 on Route 309 in northwestern Ohio, this mall has been a cornerstone of Lima's retail landscape for decades. Acquired by The Rocky Companies in 2023, Lima Town Center is poised for an exciting transformation. Plans for redevelopment include the introduction of several new and highly anticipated national anchor retailers to the area.",
    image: Lima,
    icon: ShoppingBag,
    mapLink: 'https://www.google.com/maps/place/Lima+Mall/@40.7657389,-84.1540441,17z/data=!3m1!4b1!4m6!3m5!1s0x883ef3ae80e7dce3:0x6772059975eabf42!8m2!3d40.7657349!4d-84.1514692!16s%2Fm%2F0qkx3dt?entry=ttu'
  },
  {
    name: "Findlay Town Center",
    location: "Findlay, Ohio",
    description: "Located in the vibrant micropolitan city of Findlay, Ohio, Findlay Town Center is a mixed-use development totaling more than 575,000 square feet of retail and flex warehouse space. Situated just east of I-75 on Route 224 in northwestern Ohio, this development has been a landmark in the Findlay retail corridor for decades. Acquired by The Rocky Companies in 2022, Findlay Town Center will undergo a revitalization in 2025 to bring name brand retailers and national tenants to the market.",
    image: ComingSoon,
    icon: ShoppingBag,
    flyer: '/flyers/findlay-town-center-flyer.pdf',
    mapLink: 'https://www.google.com/maps/place/Findlay+Mall/@41.0547902,-83.6176176,16z/data=!3m1!4b1!4m6!3m5!1s0x883eab18797767d7:0x449e01cd9a3b507d!8m2!3d41.0547862!4d-83.6150427!16s%2Fg%2F11k5ltsdc0?entry=ttu'
  },
  {
    name: "Best Buy",
    location: "Lansing, Michigan",
    description: "Located in Lansing, Michigan, at 5216 W. Saginaw Hwy, this 45,000-square-foot retail building leased to Best Buy was purchased by The Rocky Companies in 2022. Situated on 4.5 acres adjacent to the Lansing Mall, this property enjoys prime visibility and frontage along W. Saginaw Highway, the premier retail corridor in the Lansing market. Currently leased to Best Buy, this property stands as a testament to both strategic location and thriving commerce.",
    image: BB,
    icon: ShoppingBag,
    mapLink: 'https://www.google.com/maps/place/4216+W+Saginaw+Hwy,+Lansing,+MI+48917/@42.7413477,-84.6100847,17z/data=!3m1!4b1!4m6!3m5!1s0x8822955497819dc3:0x46d09da22ca33ac0!8m2!3d42.7413438!4d-84.6075098!16s%2Fg%2F11c5lq_qqd?entry=ttu'
  },
  {
    name: "Collins Avenue Shopping Center",
    location: "Baltimore, Maryland",
    description: "This 30,000 SF urban shopping center underwent a complete structural and full-scale renovation by Rocky Companies. Purchased mostly vacant in 2019 after a neighborhood thrift store closed its doors after 60 years, the center needed new life. Floor elevations in half of the shopping center were raised by almost two feet to bring the center to a single level grade. Load bearing walls were removed to allow for contiguous floor space. The center is now fully leased by 'Amazon proof' Tenants.",
    image: Collins,
    icon: ShoppingBag,
    flyer: '/flyers/collins-avenue-flyer.pdf',
    mapLink: 'https://www.google.com/maps/place/Collins+Avenue+Shopping+Center/@39.2296892,-76.9624119,11z/data=!4m10!1m2!2m1!1scollins+avenue+shopping+center!3m6!1s0x89c81d0045805f0d:0x3d10c365f4b133d5!8m2!3d39.2824899!4d-76.6831417!15sCh5jb2xsaW5zIGF2ZW51ZSBzaG9wcGluZyBjZW50ZXKSAQ9idXNpbmVzc19jZW50ZXLgAQA!16s%2Fg%2F11lm9zy9p7?entry=ttu'
  },
  {
    name: "George Palmer Shopping Center",
    location: "Capital Heights, Maryland",
    description: "George Palmer Shopping Center is an 18,000 square foot outdoor strip center located in Capital Heights, Maryland. One of the Rocky Companies principal's first acquisitions, the property was purchased in 2015 and was in need of several major upgrades. The center was renovated in 2016 and new national retail tenants were procured vastly improving the properties incomes and value.",
    image: GP,
    icon: ShoppingBag,
    mapLink: 'https://www.google.com/maps/place/Dollar+Tree/@38.8975247,-76.9057301,20z/data=!4m15!1m8!3m7!1s0x89b7bf45966b401b:0x6d7084b68874bf82!2s5944+Martin+Luther+King+Jr+Hwy,+Capitol+Heights,+MD+20743!3b1!8m2!3d38.8982838!4d-76.9053771!16s%2Fg%2F11c5pk4h9x!3m5!1s0x89b7bf15b5565071:0x50f079884f8c129f!8m2!3d38.8980038!4d-76.9057373!16s%2Fg%2F11vb8brf51?entry=ttu'
  },
  {
    name: (
      <>
        Mary Washington Healthcare&nbsp;
        <span className="block lg:inline">Surgical Center</span>
      </>
    ),
    location: "4548 Empire Ct, Fredericksburg, VA",
    description: "This surgical center was purchased by the Rocky Companies in 2020. Leased by Mary Washington Healthcare, the largest healthcare provider in the region, the 14,000 square foot property is a full service medical and surgical center located minutes from Mary Washington Hospital.",
    image: MWHS,
    icon: Stethoscope,
    mapLink: 'https://www.google.com/maps/place/Mary+Washington+Healthcare/@38.4150311,-77.4100723,16z/data=!3m2!4b1!5s0x89b6ef16a7765091:0x54fcd56183de2e88!4m6!3m5!1s0x89b6ef16ec2994fd:0x913b0c5d2ea7b16d!8m2!3d38.4150269!4d-77.4074974!16s%2Fg%2F12641fp_s?entry=ttu'
  },
  {
    name: "Sheetz",
    location: "1880 Tiffin Avenue, Findlay, Ohio",
    description: "In 2024 Sheetz Inc. expanded its footprint to include multiple gas station / convenience stores in Findlay, Ohio. Its newest site on the corner of Route 224 Tiffin Avenue @ Croy Drive, will be open for business in early 2025.  Sheetz has revolutionized the C-store industry and is recognizable from the east coast to the Midwest. The brand has become a cultural phenomenon and is well known for its quality “made to order” food and superior aesthetics.",
    image: Sheetz,
    icon: ShoppingBag,
    mapLink: 'https://www.google.com/maps/place/1880+Tiffin+Ave,+Findlay,+OH+45840/@41.0532276,-83.6170885,17z/data=!3m1!4b1!4m6!3m5!1s0x883eab17ee678691:0x3a2b5902a00762a!8m2!3d41.0532276!4d-83.6145136!16s%2Fg%2F11bw432w4r?entry=ttu'
  },
  {
    name: "Dollar Tree",
    location: "5013 York Road, Baltimore, Maryland",
    description: "This urban infill Dollar Tree development by Rocky Companies required the assemblage of 3 lots: a former church, a house, and a vacant 10,000 square foot corner retail store. The development was a creative reimagining of a city block that attracted Dollar Tree on a 10-year lease. The project was a boon to the neighborhood, as it eliminated vacancy and blight and brought a needed general merchandiser to the area. The full-scale development took 6 months to complete.",
    image: Dollar,
    icon: ShoppingBag,
    mapLink: 'https://www.google.com/maps/place/5013+York+Rd,+Baltimore,+MD+21212/@39.3498238,-76.6119639,17z/data=!3m1!4b1!4m6!3m5!1s0x89c8056d1f9225a7:0x2492153a248c3b09!8m2!3d39.3498197!4d-76.609389!16s%2Fg%2F11s7wrdc_t?entry=ttu'
  },
  {
    name: "Raising Canes",
    location: "1870 Tiffin Avenue, Findlay, Ohio",
    description: (
      <>
        Raising Cane’s made its entry to the Findlay Ohio market in 2024. As part of the Findlay Town Center and located along the well-traveled Route 224 Tiffin Avenue, this latest QSR addition to Findlay’s prime retail corridor opened with great fanfare. 
        <a 
          href="https://www.facebook.com/watch/?v=1474552713441229" 
          target="_blank" 
          rel="noopener noreferrer"
          className="text-blue-600 underline hover:text-blue-800"
        >
          Watch the video here.
        </a>
        {" "}The extraordinarily popular and fast-growing chain “Canes” is well known for its mouthwatering chicken fingers, positive work environment, and community involvement.
      </>
    ),
    image: Raisincanes,
    icon: ShoppingBag,
    mapLink: 'https://www.google.com/maps/place/1870+Tiffin+Ave,+Findlay,+OH+45840/@41.053764,-83.6126156,17z/data=!3m1!4b1!4m6!3m5!1s0x883eab3d4c9115b3:0xc29714ab3fdcc3e9!8m2!3d41.053764!4d-83.6126156!16s%2Fg%2F11y4qm0bt2?entry=ttu'
  },
  {
    name: "Starbucks",
    location: "Pikesville, Maryland",
    description: "The Rocky Companies purchased this vacant former bank branch in Pikesville, MD. The 3,200 square foot drive-thru building required a complete redevelopment to bring the Pikesville community a much-needed drive-thru upscale coffee shop. In 2020, the property was delivered as a turnkey store to Starbucks, giving Pikesville, Maryland its first and only Starbucks drive-through location.",
    image: Starbucks,
    icon: Coffee,
    mapLink: 'https://www.google.com/maps/place/Starbucks/@39.3646193,-76.7155676,16z/data=!3m1!4b1!4m6!3m5!1s0x89c81bc21f59c8f5:0x9cfe6e9e087d2386!8m2!3d39.3646152!4d-76.7129927!16s%2Fg%2F11n08591yv?entry=ttu'
  },
  {
    name: "Lanham Shopping Center",
    location: "8950 Annapolis Road, Lanham, Maryland",
    description: "A former free-standing restaurant, the property underwent major renovations in 2017 anchored by 7 Eleven. The vibrant strip center sits at the entrance to the Washington Beltway and has been 100% leased for close to 20 years.",
    image: Lanham,
    icon: ShoppingBag,
    mapLink: 'https://www.google.com/maps/place/8950+Annapolis+Rd,+Lanham,+MD+20706/@38.9616081,-76.8654258,17z/data=!3m1!4b1!4m5!3m4!1s0x89b7c1a4fac7bfbd:0xbb4d2b8f2e686433!8m2!3d38.961604!4d-76.8628509?entry=ttu'
  },

];

const PropertySection = ({ property, isActive, isDesktop }) => {
  const controls = useAnimation();

  useEffect(() => {
    if (isDesktop) {
      if (isActive) {
        controls.start('visible');
      } else {
        controls.start('hidden');
      }
    } else {
      controls.start('visible'); // No animations on mobile, just make it visible
    }
  }, [isActive, controls, isDesktop]);

  return (
    <motion.section 
      className="h-screen snap-start flex flex-col lg:flex-row bg-white overflow-hidden"
      initial="hidden"
      animate={controls}
      variants={{
        visible: { opacity: 1, scale: 1, transition: { duration: 0.8 } },
        hidden: { opacity: 0, scale: 0.95 }
      }}
    >
      <motion.div 
        className="w-full lg:w-1/2 h-1/2 lg:h-full flex items-center justify-center bg-white"
        variants={{
          visible: { opacity: 1, scale: 1, transition: { duration: 0.8 } },
          hidden: { opacity: 0, scale: 0.95 }
        }}
      >
        <div className="relative w-full h-full bg-white">
          <img 
            src={property.image} 
            alt={property.name} 
            className="w-full h-full object-contain lg:object-scale-down" 
          />
        </div>
      </motion.div>
      <motion.div 
        className="w-full lg:w-1/2 p-8 lg:p-16 flex flex-col justify-center bg-white overflow-y-auto"
        variants={{
          visible: { opacity: 1, x: 0, transition: { duration: 0.8, delay: 0.2 } },
          hidden: { opacity: 0, x: 50 }
        }}
        style={{ maxHeight: 'calc(100vh - 2rem)' }} 
      >
<div className="flex items-center space-x-3 mb-4 bg-white">
  <property.icon className="text-blue-600 flex-shrink-0" size={28} />
  <h2 className="text-3xl lg:text-4xl font-bold text-gray-800">{property.name}</h2>
</div>
        <div className="flex items-center space-x-2 text-gray-600 mb-6 bg-white">
          <motion.a 
            href={property.mapLink} 
            target="_blank" 
            className="flex items-center space-x-2 text-gray-600 hover:text-blue-600 transition-colors"
          >
            <MapPin size={20} className="flex-shrink-0" />
            <h3 className="text-xl lg:text-2xl">{property.location}</h3>
          </motion.a>
        </div>
        <motion.p 
          className="text-base lg:text-lg text-gray-700 leading-relaxed overflow-y-auto bg-white"
          style={{ maxHeight: '40vh' }}
          variants={{
            visible: { opacity: 1, y: 0, transition: { duration: 0.8, delay: 0.4 } },
            hidden: { opacity: 0, y: 20 }
          }}
        >
          {property.description}
        </motion.p>
        {property.flyer && (
          <motion.a 
            href={property.flyer} 
            target="_blank" 
            className="bg-blue-600 text-white px-4 py-2 rounded-lg flex items-center justify-center w-full md:w-auto hover:bg-blue-700 transition-colors mt-4"
            variants={{
              visible: { opacity: 1, y: 0, transition: { duration: 0.8, delay: 0.6 } },
              hidden: { opacity: 0, y: 20 }
            }}
          >
            View Leasing Flyer
          </motion.a>
        )}
      </motion.div>
    </motion.section>
  );
};

const PortfolioPage = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const containerRef = useRef(null);
  const isDesktop = window.innerWidth >= 1024;

  useEffect(() => {
    const container = containerRef.current;
    const sections = container.querySelectorAll('section');

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const index = Array.from(sections).indexOf(entry.target);
            setActiveIndex(index);
          }
        });
      },
      {
        threshold: 0.5, // Trigger when at least 50% of the section is visible
      }
    );

    sections.forEach((section) => {
      observer.observe(section);
    });

    return () => {
      sections.forEach((section) => {
        observer.unobserve(section);
      });
    };
  }, []);

  const scrollToTop = () => {
    containerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="min-h-screen bg-white text-black">
      <div 
        ref={containerRef} 
        className="h-screen overflow-y-scroll snap-y snap-mandatory bg-white"
        style={{ scrollBehavior: 'smooth' }}
      >
        {properties.map((property, index) => (
          <PropertySection 
            key={index} 
            property={property} 
            isActive={index === activeIndex} 
            isDesktop={isDesktop}
          />
        ))}
        <div className="flex justify-center items-center h-16 bg-white">
          <button 
            onClick={scrollToTop} 
            className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition-colors"
          >
            Scroll to Top
          </button>
        </div>
      </div>
    </div>
  );
};

export default PortfolioPage;