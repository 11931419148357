import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import LandingPage from './LandingPage';
import PortfolioPage from './PortfolioPage';
import NavBar from './NavBar'; 
import NotFound from './NotFound';
import Footer from './Footer';
import './App.css'; 

function App() {
  return (
    <Router>
      <div className="App flex flex-col min-h-screen overflow-x-hidden"> 
        <NavBar /> 
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/portfolio" element={<PortfolioPage />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </main>
        <FooterDisplay />
      </div>
    </Router>
  );
}

function FooterDisplay() {
  const location = useLocation();

  // Do not show the footer on the portfolio page or 404 page
  if (location.pathname === '/portfolio' || location.pathname === '/404') {
    return null;
  }

  return <Footer />;
}

export default App;
