import React, { useState } from 'react';
import Slider from 'react-slick';
import { motion } from 'framer-motion';
import { Mail,  MapPin, ShoppingBag, Eye } from 'lucide-react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Lima from './Images/Lima Mall Entrance 5.jpg';
import Findlay from './Images/ImageCS.jpg'
import Killeen from './Images/killeen.jpg'
// import BestBuy from './Images/bestbuy.jpg'
import agent1 from './Images/agent1.jpg';
import agent2 from './Images/agent2.jpg';
import agent3 from './Images/agent3.jpg';

const properties = [
  {
    name: "Lima Mall",
    location: "Lima, Ohio",
    description: "Situated in the vibrant micropolitan city of Lima, Ohio, Lima Mall spans an impressive 745,000 square feet, blending indoor and outdoor retail experiences. Nestled just west of route I-75 on Route 309 in northwestern Ohio, this mall has been a cornerstone of Lima's retail landscape for decades. Acquired by The Rocky Companies in 2023, Lima Mall is poised for an exciting transformation. Plans for redevelopment include the introduction of several new and highly anticipated national retailers to the area.",
    image: Lima,
    icon: ShoppingBag,
    agent: {
      name: "John Doe",
      email: "contact@rockycompanies.com",
      phone: "(410) 656-2230",
      photo: agent1,
    },
    mapLink: 'https://www.google.com/maps/place/Lima+Mall/@40.7657389,-84.1540441,17z/data=!3m1!4b1!4m6!3m5!1s0x883ef3ae80e7dce3:0x6772059975eabf42!8m2!3d40.7657349!4d-84.1514692!16s%2Fm%2F0qkx3dt?entry=ttu'
  },
  {
    name: "Killeen Mall",
    location: "Killeen, Texas",
    description: "Killeen Mall is a thriving 554,000 square foot indoor mall located in Killeen, Texas, a suburb of Austin. Purchased by The Rocky Companies in 2024, the mall features name-brand stores such as Dillards, Burlington, and JCPenney, with junior anchors TJMaxx and Home Goods scheduled to open stores in 2024. The mall boasts a 90% occupancy rate and well above national average sales volume figures.",
    image: Killeen,
    icon: ShoppingBag,
    agent: {
      name: "Jane Smith",
      email: "contact@rockycompanies.com",
      phone: "(410) 656-2230",
      photo: agent2,
    },
    mapLink: 'https://www.google.com/maps/place/Killeen+Mall/@31.0929646,-97.7197839,17z/data=!3m1!4b1!4m6!3m5!1s0x86454bbf4319351b:0x921bdab82b931add!8m2!3d31.09296!4d-97.717209!16s%2Fm%2F0pcv7jn?entry=ttu'
  },
  {
    name: "Findlay Mall",
    location: "Findlay, Ohio",
    description: "Findlay Mall is a 375,000 square foot indoor mall located at the epicenter of the growing micropolitan city of Findlay, Ohio. Just off route I-75 in northwestern Ohio, the site has anchored Findlay's main retail corridor for decades. The Rocky Companies purchased the property in 2023 with plans to redevelop the mall and bring exciting new anchors and tenants to the property.",
    image: Findlay,
    icon: ShoppingBag,
    flyer: '/flyers/findlay-mall-flyer.pdf',
    agent: {
      name: "Michael Brown",
      email: "contact@rockycompanies.com",
      phone: "(410) 656-2230",
      photo: agent3,
    },
    mapLink: 'https://www.google.com/maps/place/Findlay+Mall/@41.0547902,-83.6176176,16z/data=!3m1!4b1!4m6!3m5!1s0x883eab18797767d7:0x449e01cd9a3b507d!8m2!3d41.0547862!4d-83.6150427!16s%2Fg%2F11k5ltsdc0?entry=ttu'
  // },
  // {
  //   name: "Best Buy",
  //   location: "Findlay, Ohio",
  //   description: "Findlay Mall is a 375,000 square foot indoor mall located at the epicenter of the growing micropolitan city of Findlay, Ohio. Just off route I-75 in northwestern Ohio, the site has anchored Findlay's main retail corridor for decades. The Rocky Companies purchased the property in 2023 with plans to redevelop the mall and bring exciting new anchors and tenants to the property.",
  //   image: BestBuy,
  //   icon: ShoppingBag,
  //   flyer: '/flyers/findlay-mall-flyer.pdf',
  //   agent: {
  //     name: "Michael Brown",
  //     email: "contact@rockycompanies.com",
  //     phone: "(410) 656-2230",
  //     photo: agent3,
  //   },
  //   mapLink: 'https://www.google.com/maps/place/Findlay+Mall/@41.0547902,-83.6176176,16z/data=!3m1!4b1!4m6!3m5!1s0x883eab18797767d7:0x449e01cd9a3b507d!8m2!3d41.0547862!4d-83.6150427!16s%2Fg%2F11k5ltsdc0?entry=ttu'
  }
];

const PropertyCard = ({ property, onViewFlyer }) => (
  <motion.div 
    className="h-full flex flex-col lg:flex-row bg-white overflow-hidden w-full rounded-lg shadow-lg"
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
    style={{ minHeight: '400px' }}
  >
    <div className="w-full lg:w-1/2 h-64 lg:h-auto relative">
      <img 
        src={property.image} 
        alt={property.name} 
        className="w-full h-full object-cover rounded-t-lg lg:rounded-tr-none lg:rounded-bl-lg"
      />
    </div>
    <div className="w-full lg:w-1/2 p-8 lg:p-16 flex flex-col justify-center bg-white">
      <div className="flex items-center space-x-3 mb-4">
        <property.icon className="text-blue-600 flex-shrink-0" size={28} />
        <h2 className="text-3xl lg:text-4xl font-bold text-gray-800">{property.name}</h2>
      </div>
      <div className="flex items-center space-x-2 text-gray-600 mb-6">
        <a href={property.mapLink} target="_blank" rel="noopener noreferrer">
          <MapPin size={20} className="flex-shrink-0" />
        </a>
        <h3 className="text-xl lg:text-2xl">{property.location}</h3>
      </div>
      <p className="text-base lg:text-lg text-gray-700 leading-relaxed mb-6">
        {property.description}
      </p>
      <div className="flex items-center space-x-4 mb-6">
        <div className="flex flex-col">
          <h4 className="text-lg font-semibold">Leasing Agent</h4>
          <h4 className="text-lg font-semibold">{property.agent.name}</h4>
          <div className="flex items-center space-x-2">
            <a href={`mailto:${property.agent.email}`} className="text-blue-600 flex items-center">
              <Mail size={20} className="mr-1" /> Contact for details
            </a>
          </div>
        </div>
      </div>
      {property.flyer && (
        <button 
          onClick={() => onViewFlyer(property.flyer)} 
          className="bg-blue-600 text-white px-4 py-2 rounded-lg flex items-center justify-center w-full md:w-auto hover:bg-blue-700 transition-colors"
        >
          <Eye size={20} className="mr-2" /> View Leasing Flyer
        </button>
      )}
    </div>
  </motion.div>
);

const PropertiesSection = ({ id }) => {
  const [selectedFlyer, setSelectedFlyer] = useState(null);

  const handleViewFlyer = (flyer) => {
    setSelectedFlyer(flyer);
  };

  const handleCloseFlyer = () => {
    setSelectedFlyer(null);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true,
    adaptiveHeight: false,
    pauseOnHover: true,
    customPaging: i => (
      <div
        style={{
          width: "10px",
          height: "10px",
          backgroundColor: "white",
          borderRadius: "50%",
          display: "inline-block",
          margin: "0 5px"
        }}
      />
    ),
    appendDots: dots => (
      <div
        style={{
          position: "absolute",
          bottom: "20px",
          width: "100%",
          display: "flex",
          justifyContent: "center"
        }}
      >
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    )
  };

  return (
    <section id='for-lease' className="py-20 bg-black text-white w-full">
      <div className="w-full max-w-6xl mx-auto">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          className="text-4xl font-bold mb-12 text-center text-[#EC1D26]"
        >
          Properties for Lease
        </motion.h2>
        <Slider {...settings} className="w-full h-full">
          {properties.map((property, index) => (
            <div key={index} className="px-4">
              <PropertyCard property={property} onViewFlyer={handleViewFlyer} />
            </div>
          ))}
        </Slider>
      </div>
      {selectedFlyer && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
          <div className="relative w-full max-w-4xl bg-white rounded-lg shadow-lg">
            <button 
              onClick={handleCloseFlyer} 
              className="absolute top-2 right-2 bg-red-600 text-white rounded-full p-2 hover:bg-red-700 transition-colors"
            >
              &times;
            </button>
            <iframe 
              src={selectedFlyer} 
              className="w-full h-96" 
              title="Leasing Flyer"
            />
          </div>
        </div>
      )}
    </section>
  );
};

export default PropertiesSection;
